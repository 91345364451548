import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import '../../styles/DevArticle/Base.scss';
import img_ui_toolkit_x_font from '../../images/dev-article/ui-toolkit-x-font.png';
import img_adapt_font_asset from '../../images/dev-article/ui-toolkit-x-font/adapt-font-asset.png';
import img_set_font from '../../images/dev-article/ui-toolkit-x-font/set-font.png';
import img_no_font_result from '../../images/dev-article/ui-toolkit-x-font/no-font-result.png';
import img_window_font_asset_creator from '../../images/dev-article/ui-toolkit-x-font/window-font-asset-creator.png';
import img_font_asset_creator from '../../images/dev-article/ui-toolkit-x-font/font-asset-creator.png';
import ReactGA4 from 'react-ga4';

const Index: React.FC = () => {
  return (
    <div className="da-show">
      <Head />
      <h2>UI Toolkit で日本語フォントを表示する</h2>
      <p className="da-show-timestamp">2023.03.20</p>
      <p className="da-show-image">
        <img src={img_ui_toolkit_x_font} alt = "UI Toolkit で日本語フォントを表示する" width="400" />
      </p>
      <p>
        こんびえ〜🧜‍♂️天日江護です🐳<br />
        今回は Unity の開発ツールの UI Toolkit で日本語フォントを使っていた際に実機にビルドすると表示されない問題が発生したのでその解消方法について解説します。
      </p>
      <h3>背景</h3>
      <p>
        そもそも記事にするほどでもないかなと思っていたのですが、当時調べていた時に UI Toolkit に関する記事が少ないと感じたので寄稿することにしました。
      </p>
      <h3>問題</h3>
      <p>
        UI Toolkit を使って日本語を含む UI Document および VisualElement を生成していました。
      </p>
      <p className="da-show-image">
        <img src={img_set_font} alt = "日本語フォントを設定した図" />
      </p>
      <p>
        Unity Editor 上では正しく表示されていたのですが、 iOS や Android にビルドした途端、以下のように英数字や記号だけが表示され日本語が表示されなくなりました。
      </p>
      <p className="da-show-image">
        <img src={img_no_font_result} alt = "フォントが表示されない様子" />
      </p>
      <p>
        この問題を解消していきます。<br />
        なお、今回は UI Toolkit の基礎的な使い方には触れませんのでご容赦ください。
      </p>
      <h3>結論</h3>
      <p>
        結論から言うと、FontAsset を生成して設定すれば日本語が表示されるようになります。
      </p>
      <p className="da-show-image">
        <img src={img_adapt_font_asset} alt="FontAsset適応結果" />
      </p>
      <p>
        では、そもそも FontAsset とは何なのか、なぜ必要なのかについて先に触れておきます。
      </p>
      <h3>UI Toolkit のルーツ</h3>
      <p>
        その答えは成り立ちに起因しています。<br />
        UI Toolkit は単に HTML / CSS ベースのツールセットなだけではなく、 TextMesh Proというフォントをレンダリングする技術をベースに作られています<sup>※1</sup>。<br />
        この TextMesh Pro の技術の一部に FontAsset という Unity 独自のフォントアセット機能<sup>※2</sup>があり、これを踏襲しているため FontAsset の生成が必要になります。
      </p>
      <hr className="da-show-margin20" />
      <p>
        ※1) <a href="https://blog.unity.com/ja/engine-platform/whats-new-in-ui-toolkit" target="_blank">UI Toolkit の新機能紹介 - Unity ブログ</a><br />
        ※2) <a href="https://docs.unity3d.com/Packages/com.unity.textmeshpro@3.2/manual/FontAssetsCreator.html" target="_blank">Font Asset Creator - docs.unity3d.com</a>
      </p>
      <h3>手順</h3>
      <p>
        FontAsset の必要性について理解してもらったところで適応手順について説明します。<br />
        Unity Editor 上のタスクバーの [Window] から [Text] の [Font Asset Creator] を選択します。
      </p>
      <p className="da-show-image">
        <img src={img_window_font_asset_creator} alt="Font Asset Creatorを選ぶ" />
      </p>
      <p>
        ウィンドウが立ち上がるため、FontAsset を作りたいフォントを選択、あるいはドラッグ & ドロップします。<br />
        もしフォントを持っておらず試したい場合は基本的に無料 & 商用利用も可能で、フォントの種類も豊富にあるため <a href="https://fonts.google.com/?subset=japanese&noto.script=Jpan" target="_blank">Google Fonts</a> から選んでダウンロードするのがおすすめです。
      </p>
      <p className="da-show-image">
        <img src={img_font_asset_creator} alt="Font Asset Creator" />
      </p>
      <p>
        次に、フォントの解像度を選び [Character Set] を [Custom Characters] にします。<br /><br />
      </p>
      <p>
        [Custom Character List] の設定はやや複雑なため説明します。<br />
        <a href="https://gist.github.com/kgsi/ed2f1c5696a2211c1fd1e1e198c96ee4" target="_blank">https://gist.github.com/kgsi/ed2f1c5696a2211c1fd1e1e198c96ee4</a> に飛び、右上にある Download ZIP のボタンをクリックし、ダウンロードしたファイルを解凍すると、中に文字列が大量に入ったテキストがあるので、中身をコピペして [Custom Character List] に貼りつけます。<br />
        ここまでの手順は <a href="https://www.midnightunity.net/textmeshpro-japanese-font/" target="_blank">TextMesh Pro で日本語フォントを使う方法【Unity】 - 夜中にUnity</a> で TextMesh Pro 時代の手順が記載されているので、参考にしてみてください。
      </p>
      <p className="da-show-margin20">
        最後に、[Generate Font Atlas] を押すと、長い時間かけて Font Asset が生成されるので、生成後、ウィンドウ最下部の [Save] で保存して、UI Builder から [Font Asset] の欄で生成された Font Asset を選択すれば成功です。
      </p>
      <p className="da-show-image">
        <img src={img_adapt_font_asset} alt="FontAsset適応結果" />
      </p>
      <p className="da-show-margin20">
        今回はここまで。よき開発ライフを〜🧜‍♂️
      </p>
      <BackButton />
    </div>
  );
}

const BackButton: React.FC = () => {
  return (
    <div className="da-show-back-button">
      <Link to='/dev_article'>
        &lt; 戻る
      </Link>
    </div>
  );
}

const Head: React.FC = () => {
  const title = 'UI Toolkit で日本語フォントを表示する';
  const type = 'article';
  const img = `https://amabie-labo.com${img_ui_toolkit_x_font}`;
  const url = 'https://amabie-labo.com/dev_article/article01';
  const description = 'UI Toolkit で日本語フォントを表示する';
  const page = window.location.pathname + window.location.search;
  ReactGA4.initialize('G-ZZLMMVSZHS');
  ReactGA4.send({ hitType: "pageview", page: page, title: title, location: page });
  return (
    <HelmetProvider>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:type" content={type} />
        <meta property="og:image" content={img} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
      </Helmet>
    </HelmetProvider>
  );
}

export default Index;
