import React from 'react';
import '../styles/NotFound.scss';

const NotFound: React.FC = () => {
  return (
    <p className="not-found">NotFound</p>
  )
}

export default NotFound;
