import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './Index';

import MurderMysteryIndex from './MurderMystery/Index';
import MurderMysteryEndOfSpaceJourney from './MurderMystery/EndOfSpaceJourney';
import MurderMysteryEndOfSpaceJourneyAnotherStory from './MurderMystery/EndOfSpaceJourneyAnotherStory';

import MysterySolvingGameIndex from './MysterySolvingGame/Index';
import MysterySolvingGameBlindGirl from './MysterySolvingGame/BlindGirl';

import ToolIndex from './Tool/Index';
import ToolPikoPiko from './Tool/PikoPiko';

import KnowHowIndex from './KnowHow/Index';
import KnowHowStreaming01 from './KnowHow/Streaming01';
import KnowHowStreaming02 from './KnowHow/Streaming02';
import KnowHowStreaming03 from './KnowHow/Streaming03';

import DevArticleIndex from './DevArticle/Index';
import DevArticleArticle01 from './DevArticle/Article01';

import GameArticleIndex from './GameArticle/Index';
import GameArticlePokemonIndex from './GameArticle/Pokemon/Index';
import GameArticlePokemonSwordShield01 from './GameArticle/Pokemon/SwordShield01';
import GameArticlePokemonSwordShield02 from './GameArticle/Pokemon/SwordShield02';

import NotFound from './NotFound';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <Routes>
          <Route index element={<Index />} />
          <Route path='murder_mystery'>
            <Route index element={<MurderMysteryIndex />} />
            <Route path='end_of_space_journey' element={<MurderMysteryEndOfSpaceJourney />} />
            <Route path='end_of_space_journey_another_story' element={<MurderMysteryEndOfSpaceJourneyAnotherStory />} />
          </Route>
          <Route path='mystery_solving_game'>
            <Route index element={<MysterySolvingGameIndex />} />
            <Route path='blind_girl' element={<MysterySolvingGameBlindGirl />} />
          </Route>
          <Route path='tool'>
            <Route index element={<ToolIndex />} />
            <Route path='pikopiko' element={<ToolPikoPiko />} />
          </Route>
          <Route path='know_how'>
            <Route index element={<KnowHowIndex />} />
            <Route path='streaming01' element={<KnowHowStreaming01 />} />
            <Route path='streaming02' element={<KnowHowStreaming02 />} />
            <Route path='streaming03' element={<KnowHowStreaming03 />} />
          </Route>
          <Route path='dev_article'>
            <Route index element={<DevArticleIndex />} />
            <Route path='article01' element={<DevArticleArticle01 />} />
          </Route>
          <Route path='game_article'>
            <Route index element={<GameArticleIndex />} />
            <Route path='pokemon'>
              <Route index element={<GameArticlePokemonIndex />} />
              <Route path='sword_x_shield01' element={<GameArticlePokemonSwordShield01 />} />
              <Route path='sword_x_shield02' element={<GameArticlePokemonSwordShield02 />} />
            </Route>
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

const Header: React.FC = () => {
  return (
    <header>
      <a href="/">
        <h1>Amabie Labo</h1>
      </a>
    </header>
  );
}

const Footer: React.FC = () => {
  return (
    <footer>
      <p>Copyright &copy; 2021 <a href="https://twitter.com/amabie_mamoru">@amabie_mamoru</a>.<br className="forSP" /> All Rights Reserved.</p>
    </footer>
  );
}

export default Router;
