import React from 'react';
import '../../styles/DevArticle/Base.scss';
import { Link } from 'react-router-dom';
import img_dev_article from '../../images/dev-article-banner.png';
import img_dev_article01 from '../../images/dev-article/ui-toolkit-x-font.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA4 from 'react-ga4';

const Index: React.FC = () => {
  return (
    <div className="da-index">
      <Head />
      <h2>開発関連記事</h2>
      <p>僕も開発者の端くれなので役立ちそうな情報をアップしていきます🐳</p>
      <ul>
        <li><Link to="/dev_article/article01"><img src={img_dev_article01} alt="UI Toolkitで日本語フォントを表示する" /></Link></li>
      </ul>
      <BackButton />
    </div>
  );
}

const BackButton: React.FC = () => {
  return (
    <div className="back-button">
      <a href='/'>
        &lt; 戻る
      </a>
    </div>
  );
}

const Head: React.FC = () => {
  const title = '開発関連記事';
  const type = 'article';
  const img = `https://amabie-labo.com${img_dev_article}`;
  const url = 'https://amabie-labo.com/dev_article';
  const description = '開発関連記事です';
  const page = window.location.pathname + window.location.search;
  ReactGA4.initialize('G-ZZLMMVSZHS');
  ReactGA4.send({ hitType: "pageview", page: page, title: title, location: page });
  return (
    <HelmetProvider>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:type" content={type} />
        <meta property="og:image" content={img} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
      </Helmet>
    </HelmetProvider>
  );
}

export default Index;
